<template>
  <div class="record">
    <h2>出价/操作记录：{{$route.query.id}}</h2>
    <h3>URL：<a target="_blank">123123</a></h3>
    <DragTable :header="header" :list-query="list">
    </DragTable>
  </div>
</template>
<script>
export default {
  data() {
    return {
      header: [
        { name: '提交/操作时间', value: 'time' },
        { name: '操作对象', value: 'time' },
        { name: '变更前', value: 'time' },
        { name: '变更后', value: 'time' },
        { name: '备注', value: 'time' },
        { name: '操作人', value: 'time' },
      ],
      list: {
        data: []
      }
    }
  },
  methods: {
    getDetail() {
      this.$aioxs.get(``,{
        params: {
          id: this.$route.query.id
        }
      })
      .then(res=>{
        this.list.data = res.data
      })
      
    }
  }
}
</script>
<style lang="scss" scoped>

</style>